@font-face {
  font-family: cv;
  src: url("cv.65aebdc9.eot");
  src: url("cv.65aebdc9.eot#iefix") format("embedded-opentype"), url("cv.6e2699e4.woff") format("woff"), url("cv.5b0b608d.ttf") format("truetype"), url("cv.d72e489e.svg#cv") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class*="icon-"]:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: cv;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.icon-angular:before {
  content: "A";
}

.icon-css3:before {
  content: "B";
}

.icon-git:before {
  content: "C";
}

.icon-github:before {
  content: "D";
}

.icon-gmail:before {
  content: "E";
}

.icon-html5:before {
  content: "F";
}

.icon-javascript:before {
  content: "G";
}

.icon-jest:before {
  content: "H";
}

.icon-linkedin:before {
  content: "I";
}

.icon-react:before {
  content: "J";
}

.icon-redux:before {
  content: "K";
}

.icon-sass:before {
  content: "L";
}

.icon-twitter:before {
  content: "M";
}

.icon-typescript:before {
  content: "N";
}

/*# sourceMappingURL=index.478fa2c7.css.map */
