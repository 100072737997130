/* Generated by Glyphter (http://www.glyphter.com) on  Tue Sep 03 2019*/
@font-face {
  font-family: 'cv';
  src: url('../../public/fonts/cv.eot');
  src: url('../../public/fonts/cv.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/cv.woff') format('woff'),
    url('../../public/fonts/cv.ttf') format('truetype'),
    url('../../public/fonts/cv.svg#cv') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class*='icon-']:before {
  display: inline-block;
  font-family: 'cv';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-angular:before {
  content: '\0041';
}
.icon-css3:before {
  content: '\0042';
}
.icon-git:before {
  content: '\0043';
}
.icon-github:before {
  content: '\0044';
}
.icon-gmail:before {
  content: '\0045';
}
.icon-html5:before {
  content: '\0046';
}
.icon-javascript:before {
  content: '\0047';
}
.icon-jest:before {
  content: '\0048';
}
.icon-linkedin:before {
  content: '\0049';
}
.icon-react:before {
  content: '\004a';
}
.icon-redux:before {
  content: '\004b';
}
.icon-sass:before {
  content: '\004c';
}
.icon-twitter:before {
  content: '\004d';
}
.icon-typescript:before {
  content: '\004e';
}
